<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <form @submit.prevent="addOrUpdate" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ formTitle }}</h5>
          <button
            @click="close"
            :class="{ 'is-busy': isLoading }"
            class="btn-close"
            type="button"
          ></button>
        </div>
        <div class="modal-body">
          <!-- FIELD: Name -->
          <div class="mb-3 form-floating">
            <input
              v-model.trim="form.name"
              ref="name"
              type="text"
              class="form-control"
              id="fname"
              placeholder="Example: EMOM"
            >
            <label for="fname">Name<sup class="text-danger">*</sup></label>
            <div class="form-text">Example: EMOM. Minimum 3 characters.</div>
          </div>

          <!-- FIELD: Instructions -->
          <div class="mb-3">
            <label for="fdescription">Instructions</label>
            <textarea
              v-model.trim="form.description"
              ref="description"
              rows="3"
              class="form-control"
              id="fdescription"
              placeholder="Insert instructions..."
            ></textarea>
          </div>

          <!-- FIELD: Resource -->
          <div class="mb-3 position-relative">
            <label for="fname">
              Add one or more videos from your library
              <span class="fst-italic text-muted small">(optional)</span>
            </label>
            <div class="position-relative">
              <input
                @input="search"
                @focus="focusSearch"
                @blur="blurSearch"
                v-model.trim="keyword"
                ref="search"
                type="text"
                class="form-control"
                id="fname"
                placeholder="Search exercise videos from your library..."
              >
              <div v-if="isSearching" class="spinner">
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Searching...</span>
                </div>
              </div>
            </div>
            <InputSuggestion
              @select="selectSearchItem"
              :items="suggestions"
              :slideDirection="'down'"
            ></InputSuggestion>
            <div
              v-if="resources.length"
              class="smaller py-2 mt-3 border-top border-bottom"
            >
              <div class="flex">
                <div
                  v-for="(resource, resourceIndex) in resources"
                  :key="`key-${resourceIndex}`"
                  class="flex-item"
                >
                  <VideoTag @remove="removeItem" :item="resource"></VideoTag>
                </div>
              </div>
            </div>
          </div>

          <!-- FIELD: Error -->
          <ErrorMessage v-if="error" :error="error"></ErrorMessage>
        </div>
        <div class="modal-footer">
          <SubmitButton
            :text="buttonTitle"
            :textBusy="'Please wait...'"
            :isLoading="isLoading"
            :disabled="isLoading || isSubmitDisabled"
            class="btn btn-primary"
          ></SubmitButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { httpGet, httpPost } from '@/core/http';
import { deepCopy, debounce } from '@/core/util';

const formDefaultData = () => ({
  circuitLibraryId: null,
  authorId: '',
  name: '',
  description: '',
  resource: '[]',
});

export default {
  name: 'CircuitModal',
  components: {
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
    InputSuggestion: () => import('@/elements/InputSuggestion'),
    VideoTag: () => import('@/elements/VideoTag'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    circuit: {
      type: Object,
    },
  },
  computed: {
    ...mapState('auth', ['authUser']),
    formTitle() {
      return this.isEditMode ? 'Edit circuit' : 'Add circuit';
    },
    buttonTitle() {
      return this.isEditMode ? 'Update' : 'Submit';
    },
    isEditMode() {
      return !!this.circuit;
    },
    resources() {
      return this.$options.filters.arrayStringParsed(this.form.resource);
    },
    isSubmitDisabled() {
      const { name } = this.form;
      return name.length < 3;
    },
  },
  methods: {
    close(forced = false) {
      if (this.isLoading && forced !== true) return;
      this.$emit('close');
    },
    search() {
      this.resetSearch(true);
      if (this.keyword.length && !this.isSearching) {
        this.lookup();
      }
    },
    focusSearch() {
      this.$refs.search.select();
    },
    blurSearch() {
      setTimeout(() => {
        this.resetSearch();
      }, 250);
    },
    resetSearch(keepKeyword = false) {
      if (!keepKeyword) this.keyword = '';
      this.suggestions = null;
    },
    selectSearchItem(item) {
      const resourceIndex = this.resources.findIndex((r) => r.url === item.videoUrl);
      if (resourceIndex === -1) {
        const clone = deepCopy(this.resources);
        clone.push({
          name: item.name,
          url: item.videoUrl,
        });
        this.form.resource = JSON.stringify(clone);
      }
    },
    removeItem(item) {
      const resourceIndex = this.resources.findIndex((r) => r.url === item.url);
      if (resourceIndex > -1) {
        const clone = deepCopy(this.resources);
        clone.splice(resourceIndex, 1);
        this.form.resource = JSON.stringify(clone);
      }
    },
    async searchSuggestions() {
      this.isSearching = true;
      try {
        const { userId } = this.authUser;
        const { keyword } = this;
        const res = await httpGet('/library', {
          userId,
          keyword,
          skipPublicLibrary: true,
          skipEmptyVideo: true,
        });
        this.suggestions = res.data;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isSearching = false;
      }
    },
    async addOrUpdate() {
      this.isLoading = true;
      this.error = null;
      try {
        const payload = { ...this.form };
        const res = await httpPost('/library/circuit', payload);
        this.$emit('addOrUpdate', res.data);
        this.close(true);
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      isSearching: false,
      error: null,
      form: null,
      keyword: '',
      suggestions: null,
    };
  },
  created() {
    if (this.isEditMode) {
      this.form = deepCopy(this.circuit);
    } else {
      this.form = formDefaultData();
      this.form.authorId = this.authUser.userId;
    }

    // Register a debounced command.
    this.lookup = debounce(() => {
      this.searchSuggestions();
    }, 250);
  },
  mounted() {
    const instructionsPlaceholder = `Example:
Min 1: 10 Alternating Arm DB Snatches
Min 2: 12 Cal Row
Min 3: 50 Double Unders
- Repeat 5 rounds -
`;
    this.$refs.description.placeholder = instructionsPlaceholder;
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  position: absolute;
  top: 50%;
  right: .5rem;
  transform: translateY(-50%);
  pointer-events: none;
}
</style>
